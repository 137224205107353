<template>
  <div class="test">
    <a
      @click="$router.push({ name: 'poll', params: { id: data._id } })"
      class="swipe-fill swipe-fill--left"
      ><i class="fe fe-link-2 icon mr-2" />{{ data.title[getTrad] }}</a
    >
  </div>
</template>
<script>
import moment from 'moment'
import localization from 'moment/locale/fr'
export default {
  name: 'PollCard',
  props: {
    data: { type: Object, default: null },
  },
  data: () => ({
    apiUrl: process.env.VUE_APP_API_URL,
    localization,
  }),
  computed: {
    getTrad() {
      if (this.data.title.en && this.$i18n.locale == 'en-US') return 'en'
      if (this.data.title.ar && this.$i18n.locale == 'ar-EG') return 'ar'
      return 'fr'
    },
  },
  methods: {
    moment,
    trimWords(value, number) {
      if (!value) return ''
      if (value.split(' ').length < number) return value
      return value.split(' ').splice(0, number).join(' ') + '...'
    },
    ToText(HTML) {
      var input = HTML
      return input
        .replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi, '')
        .replace(/<[^>]+?>/g, '')
        .replace(/\s+/g, ' ')
        .replace(/ /g, ' ')
        .replace(/>/g, ' ')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';

////////////////////////////////////////
// SWIPE FILL
////////////////////////////////////////

$swipe-fill-border-width: 0px;
$swipe-fill-border-color: $secondary;
$sec-swipe-fill-border-color: $primary;
$swipe-fill-button-padding: 3px 10px;
$swipe-fill-text-color: $secondary;
$sec-swipe-fill-text-color: white;
$swipe-fill-border-radius: 0px;
$swipe-fill-background: white;
$sec-swipe-fill-background: $primary;
$swipe-fill-transition-time: 0.35s;

a {
  width: 100%;
}

.swipe-fill {
  display: inline-block;
  padding: $swipe-fill-button-padding;
  border: $swipe-fill-border-width $swipe-fill-border-color solid;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: $swipe-fill-text-color;
  position: relative;
  overflow: hidden;
  transition: color $swipe-fill-transition-time ease-in-out,
    border-color $swipe-fill-transition-time ease-in-out;
  z-index: 1;
  border-radius: $swipe-fill-border-radius;

  & .icon {
    color: $primary;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $sec-swipe-fill-background;
    transition: transform $swipe-fill-transition-time ease-in-out;
    z-index: -1;
  }

  &--up {
    &:before {
      transform: translate3d(0, 100%, 0);
    }
  }

  &--down {
    &:before {
      transform: translate3d(0, -100%, 0);
    }
  }

  &--left {
    &:before {
      transform: translate3d(-100%, 0, 0);
    }
  }

  &--right {
    &:before {
      transform: translate3d(100%, 0, 0);
    }
  }

  &:hover {
    color: $sec-swipe-fill-text-color;
    border: $swipe-fill-border-width $sec-swipe-fill-border-color solid;
    & .icon {
      color: $white;
    }
    &:before {
      transform: translate3d(0, 0, 0);
    }
  }
}

////////////////////////////////////////
// BOX FILL
////////////////////////////////////////

$box-fill-border-width: 2px;
$box-fill-border-color: black;
$sec-box-fill-border-color: black;
$box-fill-button-padding: 16px 28px;
$box-fill-text-color: black;
$sec-box-fill-text-color: white;
$box-fill-border-radius: 0px;
$box-fill-background: white;
$sec-box-fill-background: #666;
$box-fill-transition-time: 0.35s;

.box-fill {
  display: inline-block;
  padding: $box-fill-button-padding;
  border: $box-fill-border-width $box-fill-border-color solid;
  text-decoration: none;
  color: $box-fill-text-color;
  position: relative;
  overflow: hidden;
  background: $box-fill-background;
  transition: color $box-fill-transition-time ease-in-out;
  z-index: 1;
  border-radius: $box-fill-border-radius;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $sec-box-fill-background;
    transition: transform $box-fill-transition-time ease-in-out,
      opacity $box-fill-transition-time ease-in-out;
    transform: scale(0);
    z-index: -1;
    opacity: 0;
  }

  &:hover {
    color: $sec-box-fill-text-color;
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }

  &--out {
    color: $sec-box-fill-text-color;
    &:before {
      transform: scale(1);
      opacity: 1;
    }
    &:hover {
      color: $box-fill-text-color;
    }
    &:hover:before {
      transform: scale(0);
      opacity: 0;
    }
  }
}

////////////////////////////////////////
// GLOBAL SETUP
////////////////////////////////////////
$wrapper-max-width: 1000px;

body {
  font-family: sans-serif;
  max-width: $wrapper-max-width;
  margin: 0 auto;
  padding: 30px;
  font-family: 'Maven Pro', sans-serif;
  text-align: center;
}

h1 {
  font-size: 2.8rem;
}
h2 {
  font-size: 2rem;
}
h1,
h2 {
  font-family: 'Yrsa', cursive;
}

p {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

hr {
  margin: 40px auto;
  max-width: 100px;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  padding: 0;
}

.pen-intro {
  text-align: center;
}

.button-container {
  a {
    margin: 0 10px;
  }
}
</style>